<template>
<h1>MatSu Hope Radio</h1>
<p>Christian Radio for the Matanuska-Susitna Valley.</p>
<p>For more information please contact info at matsuhope.org</p>
    <h2>FCC Application Notice</h2>
    <p>On September 5, 2023, Matsu Hope Radio, applicant for a new noncommercial radio station on 97.7 FM, at Wasilla, Alaska, filed an application with the Federal Communications Commission for a construction permit. Members of the public wishing to view this application or obtain information about how to file comments and petitions concerning the application can visit the following FCC website: <a href="https://enterpriseefiling.fcc.gov/dataentry/api/download/draftcopy/FM/25076f918974ea290189c2ba333a2ba7">https://enterpriseefiling.fcc.gov/dataentry/api/download/draftcopy/FM/25076f918974ea290189c2ba333a2ba7</a>
    </p>
</template>

<script>
//import MailingListForm from './components/MailingListForm.vue'

export default {
  name: 'App',
  components: {
    //MailingListForm
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
p { text-align: left; }
</style>
